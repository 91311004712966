import React, { useEffect, useState } from 'react';
import Information from '../section_components/Information';
import useDocumentTitle from "../section_components/useDocumentTitle";

export default function Government() {

  useDocumentTitle('Government - Municipality of Arayat');
  var image_url = global.config.image_url;
  
  const [goverments, setGoverments] = useState([])
  var goverment_url = global.config.api_url + "/get_goverments";

  const fetchData = async () => {
      const response = await fetch(goverment_url)
      const data = await response.json()
      setGoverments(data)
  }

  useEffect(() => {
      fetchData()
  }, [])


  return (
    <>
    {goverments.length > 0 && (
                  <ul>
                    {goverments.map(goverment => (
                      <Information
         
                        MainTitle = {goverment.title}
                        SubTitle = {goverment.sub_title}
                        
                        SectionOneText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: goverment.description_1}}></div>
                        
                        imgSectionOne = {image_url+goverment.upper_image}

                        BoxHeading = {goverment.center_text_1}
                        imgSectionTwo = {image_url+goverment.center_image}
                        SectionTwoText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: goverment.description_2}}></div>
                        
                        

                        SectionThreeHeading = {goverment.center_text_2}
                        imgSectionThreeTop = {image_url+goverment.botom_image_1}
                        imgSectionThreeBottom = {image_url+goverment.botom_image_2}
                        SectionThreeText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: goverment.description_3}}></div>
                        
                      />            
                    ))}
                  </ul>
                )}
    </>
  )
}
