import React from 'react';

export default function Information(props) {
  return (
        <>
            <div className='content-block two-space'>
                <div className='container'>
                    <div className='heading-bar d-flex flex-column gap-lg-5 gap-3 text-center'>
                        <h2>{props.MainTitle}</h2>
                        <p className='sub-heading'>{props.SubTitle}</p>
                    </div>
                </div>
                <div className='section-block'>
                    <div className='section-item section_one'>
                        <div className='container'>
                            <div className='row section-caption g-md-5 g-sm-4 g-3 align-items-center'>
                                <div className='col-lg-6 col-12'>
                                    <div className='content-text d-flex flex-column gap-md-4 gap-2'>
                                        <p>{props.SectionOneText1}</p>
                                        <p>{props.SectionOneText2}</p>
                                        <p>{props.SectionOneText3}</p>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className='img-bar right-back-overlay-img'>
                                        <img className='img-fluid' src={props.imgSectionOne} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='section-item section_two'>
                        <div className='container'>
                            <div className='row section-caption'>
                                <div className='col-12'>
                                    <div className='box-heading text-center'>
                                        <span>{props.BoxHeading}</span>
                                    </div>
                                </div>
                                <div className='clearfix'>
                                    <img className='col-md-6 float-start me-md-4 mb-2 img-fluid' src={props.imgSectionTwo} alt="" />
                                    <div className='content-text'>
                                        <p>{props.SectionTwoText1}</p>
                                        <p>{props.SectionTwoText2}</p>
                                        <p>{props.SectionTwoText3}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='section-item section_three mt-md-5 mt-3'>
                        <div className='container'>
                            <div className='row section-caption'>
                                <div className='col-12 text-center mb-md-5 mb-3'>
                                    <h3>{props.SectionThreeHeading}</h3>
                                </div>
                                <div className='col-12'>
                                    <div className='row g-sm-4 g-2 align-items-center'>
                                        <div className='col-md-6 col-12'>
                                            <div className='img-bar d-flex flex-column gap-2 left-back-overlay-img'>
                                                <img className='img-fluid' src={props.imgSectionThreeTop} alt="" />
                                                <img className='img-fluid' src={props.imgSectionThreeBottom} alt="" />
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-12'>
                                            <div className='content-text d-flex flex-column gap-md-4 gap-2 text-md-end'>
                                                <p>{props.SectionThreeText1}</p>
                                                <p>{props.SectionThreeText2}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>
            </div>
        </>
  );
}
