import React from 'react';
import {Routes, Route} from "react-router-dom";

// <----- asset start ----------->
// ----css start---->
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../src/css/style.css';
import '../src/css/media_query.css';
// ----css end---->

// ----js start---->
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
// ----js end---->
// <----- asset end ----------->

// <----- components start ----------->
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Activities from "./pages/Activities";
import ActivityDetail from "./pages/AactivityDetail";
import Blogs from "./pages/Blogs";
import BlogDetail from "./pages/BlogDetail";
import Memorandums from "./pages/Memorandums";
import Business from './pages/Business';
import Government from './pages/Government';
import Recreation from './pages/Recreation';
import Education from './pages/Education';
import Header from "./section_components/Header";
import Footer from "./section_components/Footer";
import MemorandumsDetail from './pages/MemorandumsDetail';
import LeaderDetails from "./pages/LeaderDetails";
// <----- components end ----------->

export default function App() {
  return (
    <>
      <div className='main_wapper'>
          <Header/>
          <Routes>
              <Route exact path='/' Component={Home} />
              <Route exact path='/memorandums' Component={Memorandums} />
              <Route exact path="/memorandum/details/:id" Component={MemorandumsDetail} />
              <Route exact path='/blogs' Component={Blogs} />
              <Route exact path="/blog/details/:id" Component={BlogDetail} />
              <Route exact path='/activities' Component={Activities} />
              <Route exact path="/activity/details/:id" Component={ActivityDetail} />
              <Route exact path="/leader/details/:id" Component={LeaderDetails} />
              <Route exact path='/about-us' Component={AboutUs} />
              <Route exact path='/business' Component={Business} />
              <Route exact path='/government' Component={Government} />
              <Route exact path='/recreation' Component={Recreation} />
              <Route exact path='/education' Component={Education} />
          </Routes>
          <Footer/>
      </div>
    </>
  ); 
};

