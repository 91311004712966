import React, { useEffect, useState } from 'react';
import useDocumentTitle from "../section_components/useDocumentTitle";
import Article from '../section_components/Article';
import { useParams } from 'react-router-dom';

export default function AboutUs() {
  
  useDocumentTitle('Leader Details - Municipality of Arayat');

  const { id } = useParams()
  const [leader, setLeaders] = useState([])

  var leader_url = global.config.api_url + "/get_leader"+ '/' + id;
  var image_url = global.config.image_url;

  const fetchData = async () => {
    const response = await fetch(leader_url)
    const data = await response.json()
    setLeaders(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className='aboutus-page'>

            {leader.length > 0 && (
                leader.map(leaders => (
                    <div className='activity-detail-page detail-page'>
                            <Article 
                                HeadText = {leaders.header_text}
                                MainTitle = "Meet the GREAT LEADER"
                                Name = {leaders.contact_person_name}
                                Email = {leaders.contact_person_email}
                                SubTitle = {leaders.header_description}
                                SectionOneText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: leaders.description_1}}></div>
                                imgSectionOne = {image_url+leaders.upper_image}
                                BoxHeading = {leaders.center_text}
                                SectionTwoText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: leaders.description_2}}></div>
                                imgSectionTwo = {image_url+leaders.bellow_image}
                            />
                    </div>
                ))
            )}

        </div>    
    </>

  );
}
