import React, { useEffect, useState } from 'react';
import useDocumentTitle from "../section_components/useDocumentTitle";
import Article from '../section_components/Article';
import { useParams } from 'react-router-dom';

export default function BlogDetail() {

  useDocumentTitle('Blog Detail - Municipality of Arayat');
  var image_url = global.config.image_url;
  
  const { id } = useParams()
  const [blogs, setBlogs] = useState([])

  var blog_url = global.config.api_url + "/get_blog"+ '/' + id;

  const fetchData = async () => {
    const response = await fetch(blog_url)
    const data = await response.json()
    setBlogs(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
   <>

      {blogs.length > 0 && (
          blogs.map(blog => (
            <div className='blog-detail-page detail-page' key={`blog_details_${blog.id}`}>
                      <Article 
                          HeadText = {blog.header_text}
                          MainTitle = {blog.header_title}
                          Name = {blog.contact_person_name}
                          Email = {blog.contact_person_email}
                          SubTitle = {blog.header_description}
                          SectionOneText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: blog.description_1}}></div>
                          imgSectionOne = {image_url+blog.upper_image}
                          BoxHeading = {blog.center_text}
                          SectionTwoText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: blog.description_2}}></div>
                          imgSectionTwo = {image_url+blog.bellow_image}
                      />
            </div>
          ))
      )}
           
   </>
  )
}
