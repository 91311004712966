import React, { useEffect, useState } from 'react';
import Information from '../section_components/Information';
import useDocumentTitle from "../section_components/useDocumentTitle";

export default function Education() {

  useDocumentTitle('Education - Municipality of Arayat');

  const [educations, setEducations] = useState([])

  var educaion_url = global.config.api_url + "/get_educations";
  var image_url = global.config.image_url;

  const fetchData = async () => {
      const response = await fetch(educaion_url)
      const data = await response.json()
      setEducations(data)
  }

  useEffect(() => {
      fetchData()
  }, [])

  return (
    <>
        {educations.length > 0 && (
                  <ul>
                    {educations.map(education => (
                      <Information
         
                        MainTitle = {education.title}
                        SubTitle = {education.sub_title}
                        
                        SectionOneText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: education.description_1}}></div>
                        
                        imgSectionOne = {image_url+education.upper_image}

                        BoxHeading = {education.center_text_1}
                        imgSectionTwo = {image_url+education.center_image}
                        SectionTwoText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: education.description_2}}></div>
                        
                        

                        SectionThreeHeading = {education.center_text_2}
                        imgSectionThreeTop = {image_url+education.botom_image_1}
                        imgSectionThreeBottom = {image_url+education.botom_image_2}
                        SectionThreeText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: education.description_3}}></div>
                        
                      />            
                    ))}
                  </ul>
                )}
    </>
  )
}
