import React from 'react';

export default function ShortBanner(props) {
  return (
    <>
        <div className='short-banner-block'>
            <div className='banner-bg' style={{backgroundImage: `url(${props.ShortBannerBg})` }}>
                <div className='container h-100'>
                    <div className='banner-caption d-flex flex-column justify-content-center h-100 gap-sm-3 gap-2'>
                        <h1>{props.BannerTitle}</h1>
                        <p>{props.BannerText}</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
