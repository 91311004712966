import React, { useEffect, useState } from 'react';
import Information from '../section_components/Information';
import useDocumentTitle from "../section_components/useDocumentTitle";

export default function Recreation() {

  useDocumentTitle('Recreation - Municipality of Arayat');
  var image_url = global.config.image_url;

  const [recreations, setRecreations] = useState([])
  var recreation_url = global.config.api_url + "/get_recreations";

  const fetchData = async () => {
      const response = await fetch(recreation_url)
      const data = await response.json()
      setRecreations(data)
  }

  useEffect(() => {
      fetchData()
  }, [])

  return (
    <>
      {recreations.length > 0 && (
        <ul>
          {recreations.map(recreation => (
            <Information

              MainTitle = {recreation.title}
              SubTitle = {recreation.sub_title}
              
              SectionOneText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: recreation.description_1}}></div>
              
              imgSectionOne = {image_url+recreation.upper_image}

              BoxHeading = {recreation.center_text_1}
              imgSectionTwo = {image_url+recreation.center_image}
              SectionTwoText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: recreation.description_2}}></div>
            
              SectionThreeHeading = {recreation.center_text_2}
              imgSectionThreeTop = {image_url+recreation.botom_image_1}
              imgSectionThreeBottom = {image_url+recreation.botom_image_2}
              SectionThreeText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: recreation.description_3}}></div>
            />            
          ))}
        </ul>
      )}
    </>
  )
}
