import React from 'react';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, LinkedinShareButton, LinkedinIcon } from 'next-share';

export default function SocialShare(props) {

    const basePath = window.location.href;

    return (
        <>
            <div className='shareContainer' key={props.bannerUrl}>
                <FacebookShareButton url={`${basePath}/${props.shareUrl}`}>
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={`${basePath}/${props.shareUrl}`}>
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={`${basePath}/${props.shareUrl}`}>
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <LinkedinShareButton url={`${basePath}/${props.shareUrl}`}>
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>
            </div>
        </>
    )
}
