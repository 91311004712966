import React, { useEffect, useState } from 'react';
import useDocumentTitle from "../section_components/useDocumentTitle";
import Article from '../section_components/Article';
import { useParams } from 'react-router-dom';

export default function AactivityDetail() {

  useDocumentTitle('Acitivity Detail - Municipality of Arayat');

  const { id } = useParams()
  const [activities, setActivities] = useState([])
  var image_url = global.config.image_url;

  var activity_url = global.config.api_url + "/get_activity"+ '/' + id;

  const fetchData = async () => {
    const response = await fetch(activity_url)
    const data = await response.json()
    setActivities(data)
  }

  useEffect(() => {
  fetchData()
  }, [])

  return (
    <>
      {activities.length > 0 && (
          activities.map(activity => (
            <div className='activity-detail-page detail-page' key={`activity_details_${activity.id}`}>
              <Article 
                  HeadText = {activity.header_text}
                  MainTitle = {activity.header_title}
                  Name = {activity.contact_person_name}
                  Email = {activity.contact_person_email}
                  SubTitle = {activity.header_description}
                  SectionOneText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: activity.description_1}}></div>
                  imgSectionOne = {image_url+activity.upper_image}
                  BoxHeading = {activity.center_text}
                  SectionTwoText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: activity.description_2}}></div>
                  imgSectionTwo = {image_url+activity.bellow_image}
              />
            </div>
          ))
      )}
    </>
  )
}
