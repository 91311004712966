import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import ShortBannerBg from '../img/aditya-joshi-FOhw4A1CR1Q-unsplash-bg.jpg';
import ShortBanner from '../section_components/ShortBanner';
import { useParams } from 'react-router-dom'
import useDocumentTitle from "../section_components/useDocumentTitle";

export default function MemorandumsDetail(){

	const { id } = useParams()

	const MemorandumsOption = {
		navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="29.524" height="47.812" viewBox="0 0 29.524 47.812"><path id="ic_chevron_right_24px" d="M5.618,0,0,5.618,18.248,23.906,0,42.194l5.618,5.618L29.524,23.906Z" transform="translate(29.524 47.812) rotate(180)" fill="#fff"/></svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="29.524" height="47.812" viewBox="0 0 29.524 47.812"><path id="ic_chevron_right_24px" d="M5.618,47.812,0,42.194,18.248,23.906,0,5.618,5.618,0,29.524,23.906Z" fill="#fff"/></svg>'],
		smartSpeed:1000,
		responsiveClass:true,
		responsive:{
			0:{
				items:1,
				stagePadding:0
			},
			600:{
				items:2,
				stagePadding:0
			},
			1000:{
				items:3
			}
		}
	};

  	/* Memorandum */

	const [memorandums, setMemorandums] = useState([])
	var image_url = global.config.image_url;

	var activity_list_url = global.config.api_url + "/get_memorandum"+ '/' + id;

  	const fetchDataMemo = async () => {
      const response = await fetch(activity_list_url)
      const data = await response.json()
      setMemorandums(data)
  	}

  	useEffect(() => {
      fetchDataMemo()
  	}, [])

	/* Get All Memorandum */

	const [allmemorandums, setMemorandumsAll] = useState([])

	var all_memorandum_list_url = global.config.api_url + "/get_all_memorandums";
	
  	const fetchAllDataMemorandum = async () => {
		const response = await fetch(all_memorandum_list_url)
		const all_memo_data = await response.json()
		setMemorandumsAll(all_memo_data)
  	}

  	useEffect(() => {
      fetchAllDataMemorandum()
  	}, [])

	useDocumentTitle('Memorandums - Municipality of Arayat');

  	return (
    <>
		<div className='memorandums_detail_page'>
			<ShortBanner ShortBannerBg={ShortBannerBg} BannerTitle="Memorandums" />
			<div className='memorandums_detail_content two-space lightgrey-bg'>
				<div className='container'>
					{memorandums.length > 0 && (
						memorandums.map((memorandum, index) => {
							var imgData = JSON.parse(memorandum.image);
							return (
								<div className='content-block d-flex flex-column gap-4' key={`memorandum_${memorandum.id}`}>
									<h5>{memorandum.title}</h5>
									<div className='memorandums_detail_slider'>
									<OwlCarousel className="owl-theme img-slide" loop={false} nav={false} items={1} dots margin={0} >
										{
											imgData.map((slideImg, index) => {
												return (<img src={image_url+slideImg} key={index}></img>)
											})
										}
									</OwlCarousel>
									</div>
								</div>
							);
						})
					)}
				</div>
			</div>
		</div>

		{/* <div className='memoradums_slider_block bot-space'>
			<div className='container'>
				<OwlCarousel className="owl-theme memorandums_slider" loop nav  dots={false} margin={20} stagePadding={120} {...MemorandumsOption}> 
					{allmemorandums.length > 0 && (
						allmemorandums.map((allmemorandum, index) => {
							var imgData = JSON.parse(allmemorandum.image);
							return (
								<div className='memorandums_box d-flex flex-column' key={"slid_"+allmemorandum.id}>
									<span className='title'>{allmemorandum.title}</span>
									<Link to={`/memorandum/details/${allmemorandum.id}`}><img src={image_url+imgData[0]} alt="" /></Link>
									
									<div className='caption d-flex flex-column p-3'>
										<span className='heading'>{allmemorandum.description.replace(regex, '')}</span>
										<div className='bot-bar mt-auto'>
											<div className='bot-bar-caption d-flex justify-content-between align-items-center mt-3'>
												<Link to={`/memorandum/details/${allmemorandum.id}`} className="btn-bar">Read Memo</Link>
												<button className='share-btn'>SHARE</button>
											</div>
										</div>
									</div>
								</div>
							);
						})
					)}
				</OwlCarousel>
			</div>
        </div> */}

    </>
  	)
}
