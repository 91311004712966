import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useDocumentTitle from "../section_components/useDocumentTitle";
import ShortBanner from '../section_components/ShortBanner';
import ShortBannerBg from '../img/IMG_20201115_072729-1-1200x90000.jpg';
import BlogCard from '../section_components/BlogCard';
import OwlCarousel from 'react-owl-carousel';
import '../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import { format } from 'date-fns';

const PopularOption = {
	navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="13.369" height="21.65" viewBox="0 0 13.369 21.65"><path id="ic_chevron_right_24px" d="M2.544,21.65,0,19.106l8.263-8.281L0,2.544,2.544,0,13.369,10.825Z" transform="translate(13.369 21.65) rotate(180)" fill="#055100"/></svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="13.369" height="21.65" viewBox="0 0 13.369 21.65"><path id="ic_chevron_right_24px" d="M2.544,21.65,0,19.106l8.263-8.281L0,2.544,2.544,0,13.369,10.825Z" fill="#055100"/></svg>'],
	smartSpeed:1200,
	responsiveClass:true,
    responsive:{
        0:{
            items:1,
        },
        600:{
            items:2,
        },
        1000:{
            items:3
        }
    }
};

export default function Blogs(props) {

    useDocumentTitle('Blogs - Municipality of Arayat');

    const [blogs, setBlogs] = useState([])
    var blog_list_url = global.config.api_url + "/get_all_blogs";
    var image_url = global.config.image_url;

    const fetchData = async () => {
        const response = await fetch(blog_list_url)
        const data = await response.json()
        setBlogs(data)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
    <>
	
         <div className='blogs_page'>
            <ShortBanner
              ShortBannerBg = {ShortBannerBg}
              BannerTitle = <h1>Arayat <br/> Tourist Destination: <br/> Philippines' best spot</h1>
              BannerText = "Cras condimentum arcu vitae tellus viverra, id maximus mi ornare."
              BannerButton = "Read More"
            />
            <div className='card-listing two-space lightgrey-bg bot-space card_block'>
              	<div className='container'>
                    {/* Blogs page cards */}
					<div className='row gx-md-4 gy-md-5 gy-4'>
					{blogs.length > 0 && (
                        blogs.map(blog => (
                            <div className='col-lg-4 col-sm-6 col-12' key={`blog_id_${blog.id}`}>
                                <div className='card'>
                                    <Link to={`/blog/details/${blog.id}`}><img className='card-img-top' src={image_url+blog.upper_image} alt="" /></Link>
                                    <div className='card-body px-0 pb-0'>
                                        <Link to={`/blog/details/${blog.id}`}>
                                        <h5 className='card-title'>{blog.header_title}</h5>
                                        </Link>
                                        <p className='card-text mb-0'>{blog.header_description}</p>
                                        <div className='bot-bar-caption d-flex align-items-center'>
                                            <div className='name-time-box w-100'>
                                                <div className='bot-bar-caption-item d-flex align-items-center flex-wrap'>
                                                    <div className='long-box d-flex align-items-center gap-2'>
                                                        <div className='icon-bar'>
                                                            <svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
                                                                width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
                                                                <path id="Path_4" data-name="Path 4"
                                                                    d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06Zm8.5,15.3a4.176,4.176,0,0,0,2.55-1.53,7.425,7.425,0,0,0,1.19-3.57,2.815,2.815,0,0,0-.85-2.04,2.992,2.992,0,0,0-2.21-1.02h-1.7a4.963,4.963,0,0,1-1.53-.34,1.543,1.543,0,0,1-.51-1.19.883.883,0,0,1,.34-.68,1.289,1.289,0,0,1,.68-.34,1.16,1.16,0,0,1,.85.51c.34.17.51.34.68.34a1.022,1.022,0,0,0,.68-.17,1.022,1.022,0,0,0,.17-.68,2.705,2.705,0,0,0-.85-1.7,7.031,7.031,0,0,0,.85-3.23.366.366,0,0,0-.34-.34,5.255,5.255,0,0,0-1.36-.34A8.5,8.5,0,0,0,5.78,3.4,4.28,4.28,0,0,0,4.25,6.8a4.352,4.352,0,0,0,1.36,3.23,4.644,4.644,0,0,0,3.23,1.36h0v.68a2.182,2.182,0,0,0,.68,1.7,2.475,2.475,0,0,0,1.53,1.02v3.06c0,.17,0,.17.17.34S11.389,18.359,11.559,18.359Z"
                                                                    fill="#055100" />
                                                            </svg>
                                                        </div>
                                                        <p>{blog.contact_person_name}</p>
                                                    </div>
                                                    <div className='time-box d-flex align-items-center gap-2'>
                                                        <div className='icon-bar'>
                                                            <svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
                                                                width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
                                                                <path id="Path_13" data-name="Path 13"
                                                                    d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06ZM14.789,14.789l1.19-1.19-4.25-4.25L10.2,2.55H8.5V10.2a1.543,1.543,0,0,0,.51,1.19.593.593,0,0,0,.34.17Z"
                                                                    fill="#055100" />
                                                            </svg>
                                                        </div>
                                                        <p>{format(new Date(blog.created_at), 'dd-MM-yyyy')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        )}
					</div>

                    {/* Blogs page cards over */}
					<div className='popular_listing py-5'>
						<div className='heading-bar'>
							<h3>Popular</h3>
						</div>
						<div className='popular_slider'>
							<OwlCarousel className="owl-theme" loop={false } dots={false} items={3} nav margin={25} {...PopularOption} >
								<BlogCard/>
							</OwlCarousel>
						</div>
					</div>

					<div className='alsolike_listing'>
						<div className='heading-bar d-flex gap-3 justify-content-between align-items-center'>
							<h3>You may also like</h3>
							<div className='btn-block'>
								{/* <Link to="" className="btn-bar mt-2 d-inline-block yellow-bg">Explore All</Link> */}
							</div>
						</div>
                        {/* You may also like card */}
						<div className='row gy-sm-5 gy-4 full_card'>
							<div className='col-12'>
                                {blogs.length > 0 && (
                                    blogs.map(blog => (
                                        <div className='mb-5' key={`blog_slid_id_${blog.id}`}>
                                            <div className='card'>
                                                <Link to={`/blog/details/${blog.id}`}><img className='card-img-top' src={image_url+blog.upper_image} alt="" /></Link>
                                                <div className='card-body px-0 pb-0'>
                                                    <Link to={`/blog/details/${blog.id}`}>
                                                    <h5 className='card-title'>{blog.header_title}</h5>
                                                    </Link>
                                                    <p className='card-text mb-0'>{blog.header_description}</p>
                                                    <div className='bot-bar-caption d-flex align-items-center'>
                                                        <div className='name-time-box w-100'>
                                                            <div className='bot-bar-caption-item d-flex align-items-center flex-wrap'>
                                                                <div className='long-box d-flex align-items-center gap-2'>
                                                                    <div className='icon-bar'>
                                                                        <svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
                                                                            width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
                                                                            <path id="Path_4" data-name="Path 4"
                                                                                d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06Zm8.5,15.3a4.176,4.176,0,0,0,2.55-1.53,7.425,7.425,0,0,0,1.19-3.57,2.815,2.815,0,0,0-.85-2.04,2.992,2.992,0,0,0-2.21-1.02h-1.7a4.963,4.963,0,0,1-1.53-.34,1.543,1.543,0,0,1-.51-1.19.883.883,0,0,1,.34-.68,1.289,1.289,0,0,1,.68-.34,1.16,1.16,0,0,1,.85.51c.34.17.51.34.68.34a1.022,1.022,0,0,0,.68-.17,1.022,1.022,0,0,0,.17-.68,2.705,2.705,0,0,0-.85-1.7,7.031,7.031,0,0,0,.85-3.23.366.366,0,0,0-.34-.34,5.255,5.255,0,0,0-1.36-.34A8.5,8.5,0,0,0,5.78,3.4,4.28,4.28,0,0,0,4.25,6.8a4.352,4.352,0,0,0,1.36,3.23,4.644,4.644,0,0,0,3.23,1.36h0v.68a2.182,2.182,0,0,0,.68,1.7,2.475,2.475,0,0,0,1.53,1.02v3.06c0,.17,0,.17.17.34S11.389,18.359,11.559,18.359Z"
                                                                                fill="#055100" />
                                                                        </svg>
                                                                    </div>
                                                                    <p>{blog.contact_person_name}</p>
                                                                </div>
                                                                <div className='time-box d-flex align-items-center gap-2'>
                                                                    <div className='icon-bar'>
                                                                        <svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
                                                                            width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
                                                                            <path id="Path_13" data-name="Path 13"
                                                                                d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06ZM14.789,14.789l1.19-1.19-4.25-4.25L10.2,2.55H8.5V10.2a1.543,1.543,0,0,0,.51,1.19.593.593,0,0,0,.34.17Z"
                                                                                fill="#055100" />
                                                                        </svg>
                                                                    </div>
                                                                    <p>{format(new Date(blog.created_at), 'dd-MM-yyyy')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
							</div>
						</div>
                        {/* You may also like card over */}
					</div>
				</div>
            </div>
         </div>
    </>
  );
}
