import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';  
import '../../node_modules/owl.carousel/dist/assets/owl.carousel.css';   
import '../../node_modules/owl.carousel/dist/assets/owl.theme.default.css'; 
import useDocumentTitle from "../section_components/useDocumentTitle";
import ShortBanner from '../section_components/ShortBanner';
import ShortBannerBg from '../img/admin-ajaxasdadsagfd.jpg';
import { format } from 'date-fns';

const PopularOption = {
	navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="13.369" height="21.65" viewBox="0 0 13.369 21.65"><path id="ic_chevron_right_24px" d="M2.544,21.65,0,19.106l8.263-8.281L0,2.544,2.544,0,13.369,10.825Z" transform="translate(13.369 21.65) rotate(180)" fill="#055100"/></svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="13.369" height="21.65" viewBox="0 0 13.369 21.65"><path id="ic_chevron_right_24px" d="M2.544,21.65,0,19.106l8.263-8.281L0,2.544,2.544,0,13.369,10.825Z" fill="#055100"/></svg>'],
	smartSpeed:1200,
	responsiveClass:true,
    responsive:{
        0:{
            items:1,
        },
        600:{
            items:2,
        },
        1000:{
            items:3
        }
    }
};

export default function Activities() {

    useDocumentTitle('Activities - Municipality of Arayat');
    let dymCls;
    var image_url = global.config.image_url;
    
    const [activities, setActivities] = useState([])

    var activity_list_url = global.config.api_url + "/get_all_activities";
    

    const fetchData = async () => {
        const response = await fetch(activity_list_url)
        const data = await response.json()
        setActivities(data)
    }

    useEffect(() => {
        fetchData()
    }, [])

  return (
    <>
		<div className='activity_page'>
			<ShortBanner
				ShortBannerBg = {ShortBannerBg}
				BannerTitle = "Arayat: Things we Do"
				BannerText = "Local Access Road Rehabilitation improvement in Barangay Candating & Batasan"
				BannerButton = "Read More"
			/>
			<div className='card-listing two-space lightgrey-bg bot-space card_block'>
				<div className='container'>
					<div className='row gx-md-4 gy-md-5 gy-4'>

					{activities.length > 0 && (
						activities.map((activity, index) => {

							if (index % 2 === 0) {
								dymCls = "col-md-8 col-12";
							}
							else {
								dymCls = "col-md-4 col-12";
							}

							return <div className={dymCls} key={`activity_id_${activity.id}`}>
								<div className='col-12'>
									<div className='card'>
										<Link to={`/activity/details/${activity.id}`}><img className='card-img-top' src={image_url+activity.upper_image} alt="" /></Link>
										<div className='card-body'>
											<Link to={`/activity/details/${activity.id}`} ><h5 className='card-title'>{activity.header_title}</h5></Link>
											<p className='card-text mb-0'>{activity.header_description}</p>
											<div className='bot-bar-caption d-flex align-items-center'>
												<div className='like-share-box w-100'>
													<div className='bot-bar-caption-item d-flex align-items-center flex-wrap justify-content-between'>
														<div className='like-count d-flex gap-1 align-items-center'>
															<svg xmlns="http://www.w3.org/2000/svg" width="17.978" height="15.865" viewBox="0 0 17.978 15.865">
																<g id="Component_4" data-name="Component 4" transform="translate(0 0)">
																	<path id="Heart" d="M16.566,1.462a4.831,4.831,0,0,0-6.876,0l-.676.676-.676-.676A4.862,4.862,0,0,0,1.462,8.338L9.014,15.89l7.552-7.552a4.831,4.831,0,0,0,0-6.876" transform="translate(-0.025 -0.025)" fill="#055100" fillRule="evenodd"/>
																</g>
															</svg>
															<span>609</span>
														</div>
														<button className="share-btn">SHARE</button>
													</div>
												</div>
												<div className='name-time-box w-100'>
													<div className='bot-bar-caption-item d-flex align-items-center flex-wrap'>
														<div className='long-box d-flex align-items-center gap-2'>
															<div className='icon-bar'>
																<svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg" width="20.398" height="20.398" viewBox="0 0 20.398 20.398"><path id="Path_4" data-name="Path 4" d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06Zm8.5,15.3a4.176,4.176,0,0,0,2.55-1.53,7.425,7.425,0,0,0,1.19-3.57,2.815,2.815,0,0,0-.85-2.04,2.992,2.992,0,0,0-2.21-1.02h-1.7a4.963,4.963,0,0,1-1.53-.34,1.543,1.543,0,0,1-.51-1.19.883.883,0,0,1,.34-.68,1.289,1.289,0,0,1,.68-.34,1.16,1.16,0,0,1,.85.51c.34.17.51.34.68.34a1.022,1.022,0,0,0,.68-.17,1.022,1.022,0,0,0,.17-.68,2.705,2.705,0,0,0-.85-1.7,7.031,7.031,0,0,0,.85-3.23.366.366,0,0,0-.34-.34,5.255,5.255,0,0,0-1.36-.34A8.5,8.5,0,0,0,5.78,3.4,4.28,4.28,0,0,0,4.25,6.8a4.352,4.352,0,0,0,1.36,3.23,4.644,4.644,0,0,0,3.23,1.36h0v.68a2.182,2.182,0,0,0,.68,1.7,2.475,2.475,0,0,0,1.53,1.02v3.06c0,.17,0,.17.17.34S11.389,18.359,11.559,18.359Z" fill="#055100"/></svg>
															</div>
															<p>{activity.contact_person_name}</p>
														</div>
														<div className='time-box d-flex align-items-center gap-2'>
															<div className='icon-bar'>
																<svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg" width="20.398" height="20.398" viewBox="0 0 20.398 20.398"><path id="Path_13" data-name="Path 13" d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06ZM14.789,14.789l1.19-1.19-4.25-4.25L10.2,2.55H8.5V10.2a1.543,1.543,0,0,0,.51,1.19.593.593,0,0,0,.34.17Z" fill="#055100"/></svg>
															</div>
															<p>{format(new Date(activity.created_at), 'dd-MM-yyyy')}</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>;
						})
					)}
				</div>
					<div className='popular_listing py-5'>
						<div className='heading-bar'>
							<h3>Popular</h3>
						</div>
						<div className='popular_slider'>
						{activities.length > 0 && (
							<OwlCarousel className="owl-theme" loop={false } dots={false} items={3} nav margin={25} {...PopularOption} >
            {activities.map(activity => (
							
							
                // Popular Activities cards
                <div key={`activity_slider_id_${activity.id}`}>
                    <div className='card'>
                        <Link to={`/activity/details/${activity.id}`}><img className='card-img-top' src={image_url+activity.upper_image} alt="" /></Link>
                        <div className='card-body px-0 pb-0'>
                            <Link to={`/activity/details/${activity.id}`}>
                            <h5 className='card-title'>{activity.header_title}</h5>
                            </Link>
                            <p className='card-text mb-0'>{activity.header_description}</p>
                            <div className='bot-bar-caption d-flex align-items-center'>
                                <div className='name-time-box w-100'>
                                    <div className='bot-bar-caption-item d-flex align-items-center flex-wrap'>
                                        <div className='long-box d-flex align-items-center gap-2'>
                                            <div className='icon-bar'>
                                                <svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
                                                    width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
                                                    <path id="Path_4" data-name="Path 4"
                                                        d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06Zm8.5,15.3a4.176,4.176,0,0,0,2.55-1.53,7.425,7.425,0,0,0,1.19-3.57,2.815,2.815,0,0,0-.85-2.04,2.992,2.992,0,0,0-2.21-1.02h-1.7a4.963,4.963,0,0,1-1.53-.34,1.543,1.543,0,0,1-.51-1.19.883.883,0,0,1,.34-.68,1.289,1.289,0,0,1,.68-.34,1.16,1.16,0,0,1,.85.51c.34.17.51.34.68.34a1.022,1.022,0,0,0,.68-.17,1.022,1.022,0,0,0,.17-.68,2.705,2.705,0,0,0-.85-1.7,7.031,7.031,0,0,0,.85-3.23.366.366,0,0,0-.34-.34,5.255,5.255,0,0,0-1.36-.34A8.5,8.5,0,0,0,5.78,3.4,4.28,4.28,0,0,0,4.25,6.8a4.352,4.352,0,0,0,1.36,3.23,4.644,4.644,0,0,0,3.23,1.36h0v.68a2.182,2.182,0,0,0,.68,1.7,2.475,2.475,0,0,0,1.53,1.02v3.06c0,.17,0,.17.17.34S11.389,18.359,11.559,18.359Z"
                                                        fill="#055100" />
                                                </svg>
                                            </div>
                                            <p>{activity.contact_person_name}</p>
                                        </div>
                                        <div className='time-box d-flex align-items-center gap-2'>
                                            <div className='icon-bar'>
                                                <svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
                                                    width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
                                                    <path id="Path_13" data-name="Path 13"
                                                        d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06ZM14.789,14.789l1.19-1.19-4.25-4.25L10.2,2.55H8.5V10.2a1.543,1.543,0,0,0,.51,1.19.593.593,0,0,0,.34.17Z"
                                                        fill="#055100" />
                                                </svg>
                                            </div>
                                            <p>{format(new Date(activity.created_at), 'dd-MM-yyyy')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='user-box w-100'>
                                <div className='bot-bar-caption-item d-flex align-items-center flex-wrap'>
                                    <div className='long-box d-flex align-items-center gap-2'>
                                        <div className='icon-bar'>
                                            <svg id="Avatar" xmlns="http://www.w3.org/2000/svg" width="27.418" height="27.418"
                                                viewBox="0 0 27.418 27.418">
                                                <circle id="Ellipse_25" data-name="Ellipse 25" cx="13.709" cy="13.709"
                                                    r="13.709" transform="translate(0 0)" fill="#055100" />
                                                <path id="Union_5" data-name="Union 5"
                                                    d="M0,11.1V9.714C0,8.188,2.5,6.939,5.551,6.939S11.1,8.188,11.1,9.714V11.1ZM2.775,2.776A2.775,2.775,0,1,1,5.551,5.551,2.776,2.776,0,0,1,2.775,2.776Z"
                                                    transform="translate(8.402 8.182)" fill="#fff" />
                                            </svg>
                                        </div>
                                        <p>by Name Surname</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                // Popular Activities cards over
            
								
								))}
							</OwlCarousel>
							
							)}
						</div>
					</div> 
					<div className='alsolike_listing'>
						<div className='heading-bar d-flex gap-3 justify-content-between align-items-center'>
							<h3>You may also like</h3>
							<div className='btn-block'>
								{/* <Link to="" className="btn-bar mt-2 d-inline-block yellow-bg">Explore All</Link> */}
							</div>
						</div>
						<div className='row gy-sm-5 gy-4 full_card'>
							<div className='col-12'>
							{activities.length > 0 && (
                                    activities.map(activity => (
                                        <div className='mb-5' key={`activity_card_id_${activity.id}`}>
                                            <div className='card'>
                                                <Link to={`/activity/details/${activity.id}`}><img className='card-img-top' src={image_url+activity.upper_image} alt="" /></Link>
                                                <div className='card-body px-0 pb-0'>
                                                    <Link to={`/activity/details/${activity.id}`}>
                                                        <h5 className='card-title'>{activity.header_title}</h5>
                                                    </Link>
                                                    <p className='card-text mb-0'>{activity.header_description}</p>
                                                    <div className='bot-bar-caption d-flex align-items-center'>
                                                        <div className='name-time-box w-100'>
                                                            <div className='bot-bar-caption-item d-flex align-items-center flex-wrap'>
                                                                <div className='long-box d-flex align-items-center gap-2'>
                                                                    <div className='icon-bar'>
                                                                        <svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
                                                                            width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
                                                                            <path id="Path_4" data-name="Path 4"
                                                                                d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06Zm8.5,15.3a4.176,4.176,0,0,0,2.55-1.53,7.425,7.425,0,0,0,1.19-3.57,2.815,2.815,0,0,0-.85-2.04,2.992,2.992,0,0,0-2.21-1.02h-1.7a4.963,4.963,0,0,1-1.53-.34,1.543,1.543,0,0,1-.51-1.19.883.883,0,0,1,.34-.68,1.289,1.289,0,0,1,.68-.34,1.16,1.16,0,0,1,.85.51c.34.17.51.34.68.34a1.022,1.022,0,0,0,.68-.17,1.022,1.022,0,0,0,.17-.68,2.705,2.705,0,0,0-.85-1.7,7.031,7.031,0,0,0,.85-3.23.366.366,0,0,0-.34-.34,5.255,5.255,0,0,0-1.36-.34A8.5,8.5,0,0,0,5.78,3.4,4.28,4.28,0,0,0,4.25,6.8a4.352,4.352,0,0,0,1.36,3.23,4.644,4.644,0,0,0,3.23,1.36h0v.68a2.182,2.182,0,0,0,.68,1.7,2.475,2.475,0,0,0,1.53,1.02v3.06c0,.17,0,.17.17.34S11.389,18.359,11.559,18.359Z"
                                                                                fill="#055100" />
                                                                        </svg>
                                                                    </div>
                                                                    <p>{activity.contact_person_name}</p>
                                                                </div>
                                                                <div className='time-box d-flex align-items-center gap-2'>
                                                                    <div className='icon-bar'>
                                                                        <svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
                                                                            width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
                                                                            <path id="Path_13" data-name="Path 13"
                                                                                d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06ZM14.789,14.789l1.19-1.19-4.25-4.25L10.2,2.55H8.5V10.2a1.543,1.543,0,0,0,.51,1.19.593.593,0,0,0,.34.17Z"
                                                                                fill="#055100" />
                                                                        </svg>
                                                                    </div>
                                                                    <p>{format(new Date(activity.created_at), 'dd-MM-yyyy')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='user-box w-100'>
                                                        <div className='bot-bar-caption-item d-flex align-items-center flex-wrap'>
                                                            <div className='long-box d-flex align-items-center gap-2'>
                                                                <div className='icon-bar'>
                                                                    <svg id="Avatar" xmlns="http://www.w3.org/2000/svg" width="27.418" height="27.418"
                                                                        viewBox="0 0 27.418 27.418">
                                                                        <circle id="Ellipse_25" data-name="Ellipse 25" cx="13.709" cy="13.709"
                                                                            r="13.709" transform="translate(0 0)" fill="#055100" />
                                                                        <path id="Union_5" data-name="Union 5"
                                                                            d="M0,11.1V9.714C0,8.188,2.5,6.939,5.551,6.939S11.1,8.188,11.1,9.714V11.1ZM2.775,2.776A2.775,2.775,0,1,1,5.551,5.551,2.776,2.776,0,0,1,2.775,2.776Z"
                                                                            transform="translate(8.402 8.182)" fill="#fff" />
                                                                    </svg>
                                                                </div>
                                                                <p>{format(new Date(activity.created_at), 'dd-MM-yyyy')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
							</div>							
						</div>
					</div> 
				</div>
			</div>	
		</div>
    </>
  );
}
