import React from 'react';
import ShortBanner from '../section_components/ShortBanner';
import useDocumentTitle from "../section_components/useDocumentTitle";
import ShortBannerBg from '../img/aditya-joshi-FOhw4A1CR1Q-unsplash-bg.jpg';
import MemorandumsCard from '../section_components/MemorandumsCard';

export default function Memorandums(props) {

  useDocumentTitle('Memorandums - Municipality of Arayat');

  return (
    <>
        <div className='memorandums_page'>
            <ShortBanner
              ShortBannerBg = {ShortBannerBg}
              BannerTitle = "Memorandums"
            />
            <div className='memorandums_card_listing two-space bot-space lightgrey-bg'>
                <div className='container'>
                  <MemorandumsCard />
                </div>
            </div>
        </div>
    </>
  );
}
