import React, { useEffect, useState } from 'react';
import Information from '../section_components/Information';
import useDocumentTitle from "../section_components/useDocumentTitle";

export default function Business() {
  
  useDocumentTitle('Business - Municipality of Arayat');
  var image_url = global.config.image_url;

  const [business, setBusiness] = useState([])

  var business_url = global.config.api_url + "/get_business";

  const fetchData = async () => {
      const response = await fetch(business_url)
      const data = await response.json()
      setBusiness(data)
  }

  useEffect(() => {
      fetchData()
  }, [])

  return (
    <>
                {business.length > 0 && (
                  <ul>
                    {business.map(busines => (
                      <Information
         
                        MainTitle = {busines.title}
                        SubTitle = {busines.sub_title}
                        
                        SectionOneText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: busines.description_1}}></div>
                        
                        imgSectionOne = {image_url+busines.upper_image}

                        BoxHeading = {busines.center_text_1}
                        imgSectionTwo = {image_url+busines.center_image}
                        SectionTwoText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: busines.description_2}}></div>
                        
                        

                        SectionThreeHeading = {busines.center_text_2}
                        imgSectionThreeTop = {image_url+busines.botom_image_1}
                        imgSectionThreeBottom = {image_url+busines.botom_image_2}
                        SectionThreeText1 = <div className='margin_botom' dangerouslySetInnerHTML={{__html: busines.description_3}}></div>
                        
                      />            
                    ))}
                  </ul>
                )}
    </>
  );
}
