import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';  
import '../../node_modules/owl.carousel/dist/assets/owl.carousel.css';   
import '../../node_modules/owl.carousel/dist/assets/owl.theme.default.css'; 

const LeaderOption = {
    smartSpeed:1000,
    center:true,
    responsiveClass:true,
    responsive:{
        0:{
            items:1,
            stagePadding:30
        },
        600:{
            items:2,
            stagePadding:30
        },
        1000:{
            items:3
        }
    }
  };

export default function LeaderSlider() {

    const [leadersliders, setLeaderSliders] = useState([])
    var image_url = global.config.image_url;

    var leader_slider_list_url = global.config.api_url + "/get_leader_sliders";

    const fetchDataMemo = async () => {
        const response = await fetch(leader_slider_list_url)
        const leader_slider_data = await response.json()
        setLeaderSliders(leader_slider_data)
    }

    useEffect(() => {
        fetchDataMemo()
    }, [])
    
  return (
    <>
        {leadersliders.length > 0 && (
            <OwlCarousel className="owl-theme Leader_slider mt-sm-5 mt-4" loop nav={false} items={3}  margin={0} stagePadding={0} {...LeaderOption}> 
                {leadersliders.map(leaderslider => (
                    <div key={`leader_slider_id_${leaderslider.id}`}>
                        <div className='leader_box d-flex flex-column align-items-center text-center gap-3 justify-content-center'>
                            <div className='picture-bar d-flex justify-content-center align-items-center custom_img'>
                                <Link to={`/leader/details/${leaderslider.id}`} >
                                    <img src={image_url+leaderslider.profile_picture} alt="" />
                                </Link>
                            </div>
                            <div className='name-block'>
                                <span>{leaderslider.contact_person_name}</span>
                            </div>
                            <p className='des-text'>{leaderslider.header_description}</p>
                        </div>
                    </div>
            	))}
            </OwlCarousel>  
        )}
    </>
  )
}
