import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../img/logo.png';
import Spinner from "../section_components/Spinner";

export default function Header() {

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const handleClickScroll = () => {
        const element = document.getElementById('contact-form');
        if(element){
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const navigate = useNavigate();

    const handleClickScroll2 = () => {
        const element = document.getElementById('greatLeader');
        if(element){
            element.scrollIntoView({ behavior: 'smooth' });
        }
        else{
            setTimeout(() => {
                navigate('/', { replace: true });
            }, 100);
        }
    };

    return (
    <>
    <header>

        {loading ? (
            <Spinner />
        ) : (
            <div></div>
        )}

        <div className='top-bar'>
            <div className='container'>
                <div className='caption'>
                    <Link to="#" onClick={handleClickScroll}>Contact Us</Link>
                </div>
            </div>
        </div>
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                <Link exact className="navbar-brand" to="/">
                    <img src={logo} alt="" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className='bar'></span>
                    <span className='bar'></span>
                    <span className='bar'></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-5">
                        <li className="nav-item">
                            <Link exact className="nav-link" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link exact className="nav-link" to="/memorandums">Memorandums</Link>
                        </li>
                        <li className="nav-item">
                            <Link exact className="nav-link" to="/blogs">Blogs</Link>
                        </li>
                        <li className="nav-item">
                            <Link exact className="nav-link" to="/activities">ACTIVITIES</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="#" className="nav-link" onClick={handleClickScroll2}>LEADERS</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
    </>
  );
};
