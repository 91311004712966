import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

export default function BlogCard() {

    const [blogs, setBlogs] = useState([])
    var image_url = global.config.image_url;
    
    var blog_list_url = global.config.api_url + "/get_all_blogs";

    const fetchData = async () => {
        const response = await fetch(blog_list_url)
        const data = await response.json()
        setBlogs(data)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
    <>
        {blogs.length > 0 && (
            blogs.map(blog => (
                // Popular blogs cards
                <div key={`blog_card_id_${blog.id}`}>
                    <div className='card'>
                        <Link to={`/blog/details/${blog.id}`}><img className='card-img-top' src={image_url+blog.upper_image} alt="" /></Link>
                        <div className='card-body px-0 pb-0'>
                            <Link to={`/blog/details/${blog.id}`}>
                            <h5 className='card-title'>{blog.header_title}</h5>
                            </Link>
                            <p className='card-text mb-0'>{blog.header_description}</p>
                            <div className='bot-bar-caption d-flex align-items-center'>
                                <div className='name-time-box w-100'>
                                    <div className='bot-bar-caption-item d-flex align-items-center flex-wrap'>
                                        <div className='long-box d-flex align-items-center gap-2'>
                                            <div className='icon-bar'>
                                                <svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
                                                    width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
                                                    <path id="Path_4" data-name="Path 4"
                                                        d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06Zm8.5,15.3a4.176,4.176,0,0,0,2.55-1.53,7.425,7.425,0,0,0,1.19-3.57,2.815,2.815,0,0,0-.85-2.04,2.992,2.992,0,0,0-2.21-1.02h-1.7a4.963,4.963,0,0,1-1.53-.34,1.543,1.543,0,0,1-.51-1.19.883.883,0,0,1,.34-.68,1.289,1.289,0,0,1,.68-.34,1.16,1.16,0,0,1,.85.51c.34.17.51.34.68.34a1.022,1.022,0,0,0,.68-.17,1.022,1.022,0,0,0,.17-.68,2.705,2.705,0,0,0-.85-1.7,7.031,7.031,0,0,0,.85-3.23.366.366,0,0,0-.34-.34,5.255,5.255,0,0,0-1.36-.34A8.5,8.5,0,0,0,5.78,3.4,4.28,4.28,0,0,0,4.25,6.8a4.352,4.352,0,0,0,1.36,3.23,4.644,4.644,0,0,0,3.23,1.36h0v.68a2.182,2.182,0,0,0,.68,1.7,2.475,2.475,0,0,0,1.53,1.02v3.06c0,.17,0,.17.17.34S11.389,18.359,11.559,18.359Z"
                                                        fill="#055100" />
                                                </svg>
                                            </div>
                                            <p>{blog.contact_person_name}</p>
                                        </div>
                                        <div className='time-box d-flex align-items-center gap-2'>
                                            <div className='icon-bar'>
                                                <svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
                                                    width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
                                                    <path id="Path_13" data-name="Path 13"
                                                        d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06ZM14.789,14.789l1.19-1.19-4.25-4.25L10.2,2.55H8.5V10.2a1.543,1.543,0,0,0,.51,1.19.593.593,0,0,0,.34.17Z"
                                                        fill="#055100" />
                                                </svg>
                                            </div>
                                            <p>{format(new Date(blog.created_at), 'dd-MM-yyyy')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='user-box w-100'>
                                <div className='bot-bar-caption-item d-flex align-items-center flex-wrap'>
                                    <div className='long-box d-flex align-items-center gap-2'>
                                        <div className='icon-bar'>
                                            <svg id="Avatar" xmlns="http://www.w3.org/2000/svg" width="27.418" height="27.418"
                                                viewBox="0 0 27.418 27.418">
                                                <circle id="Ellipse_25" data-name="Ellipse 25" cx="13.709" cy="13.709"
                                                    r="13.709" transform="translate(0 0)" fill="#055100" />
                                                <path id="Union_5" data-name="Union 5"
                                                    d="M0,11.1V9.714C0,8.188,2.5,6.939,5.551,6.939S11.1,8.188,11.1,9.714V11.1ZM2.775,2.776A2.775,2.775,0,1,1,5.551,5.551,2.776,2.776,0,0,1,2.775,2.776Z"
                                                    transform="translate(8.402 8.182)" fill="#fff" />
                                            </svg>
                                        </div>
                                        <p>by Name Surname</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                // Popular blogs cards over
            ))
        )}
    </>
    )
}
