import React, { useState } from 'react';
import FooterLogo from '../img/footer-logo.png';
import { NavLink, Link } from 'react-router-dom';

export default function Footer() {

    const [status, setStatus] = useState("Submit");    
    var message_url = global.config.api_url + "/message-us";

    const [state, setState] = useState({ name: "", email: "", subject: "", message: "" });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        const { name, email, subject, message } = e.target.elements;
        let details = {
            name: name.value,
            email: email.value,
            subject: subject.value,
            message: message.value,
        };
        
        try {
            let response = await fetch(message_url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify(details),
            });

            let result = await response.json();

            if(result.status === "success"){
                // setMessage('');
                alert(result.msg);
                // toast("Wow so easy!")
                setState({ name: "", email: "", subject: "", message: "" });
            }
            else{
                alert(result.msg);
                // toast("Wow so easy!")
            }
        }
        catch (error){
            // console.log(error);
        }
        
        setStatus("Submit");
    };    

  return (
    <>
        <footer id="contact-form">
            <div className='footer-top'> 
                <div className='container'>
                    <div className='footer-caption'>
                        <div className='row'>
                            <div className='col-lg-4 col-12'>
                                <div className='logo-bar m-auto text-center'>
                                    <NavLink to="/"><img src={FooterLogo} className="img-fluid" alt="" /></NavLink>
                                </div>
                                <div className='form-box'>
                                    <h5 className='mb-0'>Message us</h5>
                                    <form action="" className='row gy-2 mt-1' onSubmit={handleSubmit}>
                                        <div className='col-12'>
                                            <input className='form-control' type="text" id="name" value={state.name} onChange={(e) => { setState({ ...state, name: e.target.value }); }} placeholder='Your Name*' required/>
                                        </div>
                                        <div className='col-12'>
                                            <input className='form-control' type="email" value={state.email} onChange={(e) => { setState({ ...state, email: e.target.value }); }} id="email" placeholder='Your Email*' required/>
                                        </div>
                                        <div className='col-12'>
                                            <input className='form-control' type="text" value={state.subject} onChange={(e) => { setState({ ...state, subject: e.target.value }); }} id="subject" placeholder='Subject*' required/>
                                        </div>
                                        <div className='col-12'>
                                            <textarea className='form-control' rows="5" value={state.message} onChange={(e) => { setState({ ...state, message: e.target.value }); }} id="message" placeholder='Message*' required></textarea>
                                        </div>
                                        <div className='col-12 text-center'>
                                            <button type='submit' className='form-btn'>{status}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='col-lg-8 col-12'>
                                <div className='footer-info-block d-flex flex-column w-100 gap-lg-4 gap-5'>
                                    <div className='footer-info-top d-flex flex-lg-row flex-column gap-lg-1 gap-4 align-items-center justify-content-between w-100'>
                                        <div className='contact-box d-flex flex-column w-100 align-items-lg-start align-items-center'>
                                            <h6>Contact us</h6>
                                            <ul className='contact-info d-flex flex-column'>
                                                <li className='d-flex align-items-center'>
                                                    <div className='icon-bar'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.513" height="21.513" viewBox="0 0 21.513 21.513">
                                                            <path id="ic_perm_phone_msg_24px" d="M23.318,17.94a13.578,13.578,0,0,1-4.267-.681,1.22,1.22,0,0,0-1.219.287L15.2,20.175A18.016,18.016,0,0,1,7.327,12.31L9.956,9.669a1.173,1.173,0,0,0,.3-1.207A13.578,13.578,0,0,1,9.573,4.2,1.2,1.2,0,0,0,8.378,3H4.2A1.2,1.2,0,0,0,3,4.2,20.316,20.316,0,0,0,23.318,24.513a1.2,1.2,0,0,0,1.2-1.2V19.135A1.2,1.2,0,0,0,23.318,17.94Z" transform="translate(-3 -3)" fill="#055100"/>
                                                        </svg>
                                                    </div>
                                                    <Link className="" to="tel:1234567890">Contact Number</Link>
                                                </li>
                                                <li className='d-flex align-items-center'>
                                                    <div className='icon-bar'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.513" height="17.21" viewBox="0 0 21.513 17.21">
                                                            <path id="ic_email_24px" d="M21.362,4H4.151A2.148,2.148,0,0,0,2.011,6.151L2,19.059A2.158,2.158,0,0,0,4.151,21.21h17.21a2.158,2.158,0,0,0,2.151-2.151V6.151A2.158,2.158,0,0,0,21.362,4Zm0,4.3-8.605,5.378L4.151,8.3V6.151l8.605,5.378,8.605-5.378Z" transform="translate(-2 -4)" fill="#055100"/>
                                                        </svg>
                                                    </div>
                                                    <Link className="" to="mailto:mail@gmail.com">Email Address Here</Link>
                                                </li>
                                                <li className='d-flex align-items-center'>
                                                    <div className='icon-bar'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.059" height="21.513" viewBox="0 0 15.059 21.513">
                                                            <path id="ic_edit_location_24px" d="M12.53,2A7.538,7.538,0,0,0,5,9.53c0,5.647,7.53,13.983,7.53,13.983s7.53-8.336,7.53-13.983A7.538,7.538,0,0,0,12.53,2ZM10.852,12.756H9.3V11.208l3.6-3.593,1.538,1.538-3.593,3.6ZM15.638,7.97l-.753.753L13.336,7.174l.753-.753a.409.409,0,0,1,.581,0l.968.968A.409.409,0,0,1,15.638,7.97Z" transform="translate(-5 -2)" fill="#055100"/>
                                                        </svg>
                                                    </div>
                                                    <span>Address Here</span>
                                                </li>
                                                <li className='d-flex align-items-center'>
                                                    <div className='icon-bar'>
                                                        <svg id="time" xmlns="http://www.w3.org/2000/svg" width="21.513" height="21.513" viewBox="0 0 21.513 21.513">
                                                            <path id="Path_1316" data-name="Path 1316" d="M9,7h2.151v5.378H16.53V14.53H9Z" transform="translate(-1.47 -1.622)" fill="#055100"/>
                                                            <path id="Path_1317" data-name="Path 1317" d="M23.513,12.756A10.757,10.757,0,1,1,12.757,2,10.757,10.757,0,0,1,23.513,12.756Zm-2.151,0a8.605,8.605,0,1,1-8.605-8.605A8.605,8.605,0,0,1,21.362,12.756Z" transform="translate(-2 -2)" fill="#055100" fillRule="evenodd"/>
                                                        </svg>
                                                    </div>
                                                    <span>Government Hours</span>
                                                </li>
                                            </ul>
                                            <ul className='social-link d-flex align-items-center gap-3 mt-3'>
                                                <li>
                                                    <Link className="d-flex align-items-center justify-content-center rounded-circle" to="#">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="8.773" height="17.547" viewBox="0 0 8.773 17.547">
                                                            <path id="Facebook" d="M5.833,17.547H1.944V9.261H0V6.068H1.944V4.153C1.944,1.55,3.041,0,6.159,0h2.6V3.193H7.133c-1.214,0-1.295.446-1.295,1.279l0,1.6h2.94L8.429,9.261h-2.6v8.286Z" fill="#055100"/>
                                                        </svg>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="d-flex align-items-center justify-content-center rounded-circle" to="#">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.547" height="14.257" viewBox="0 0 17.547 14.257">
                                                            <path id="Twitter" d="M17.547,1.688a7.221,7.221,0,0,1-2.068.567A3.61,3.61,0,0,0,17.062.264a7.194,7.194,0,0,1-2.287.873A3.6,3.6,0,0,0,8.547,3.6a3.64,3.64,0,0,0,.094.821A10.219,10.219,0,0,1,1.222.659a3.6,3.6,0,0,0,1.114,4.8,3.57,3.57,0,0,1-1.63-.45v.046A3.6,3.6,0,0,0,3.593,8.588a3.594,3.594,0,0,1-.949.126,3.541,3.541,0,0,1-.677-.065,3.605,3.605,0,0,0,3.363,2.5,7.232,7.232,0,0,1-4.472,1.54A7.256,7.256,0,0,1,0,12.64a10.187,10.187,0,0,0,5.519,1.617A10.172,10.172,0,0,0,15.761,4.017c0-.157,0-.311-.01-.465a7.317,7.317,0,0,0,1.8-1.863Z" fill="#055100"/>
                                                        </svg>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="d-flex align-items-center justify-content-center rounded-circle" to="#">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.74" height="15.353" viewBox="0 0 19.74 15.353">
                                                            <path id="Youtube" d="M9.87,15.353h0c-.051,0-5.158-.037-6.7-.209-.083-.02-.184-.034-.3-.051A2.768,2.768,0,0,1,.987,14.2,5.25,5.25,0,0,1,.2,12.039,34.951,34.951,0,0,1,0,8.514V6.874C0,5.118.2,3.333.2,3.315A4.773,4.773,0,0,1,.987,1.151,2.689,2.689,0,0,1,2.9.25h0l.055,0C5.685.037,9.829,0,9.87,0s4.185.038,6.909.244l.094.008a2.513,2.513,0,0,1,1.88.9,5.25,5.25,0,0,1,.783,2.164c0,.018.2,1.8.2,3.559v1.64c0,1.721-.2,3.506-.2,3.524a5.252,5.252,0,0,1-.783,2.164,2.689,2.689,0,0,1-1.915.9h0l-.055,0C14.054,15.351,9.912,15.353,9.87,15.353ZM7.487,4.152v7.572l6.467-3.7Z" fill="#055100"/>
                                                        </svg>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="d-flex align-items-center justify-content-center rounded-circle" to="#">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.644" height="18.644" viewBox="0 0 18.644 18.644">
                                                            <path id="Exclusion_34" data-name="Exclusion 34" d="M13.09,18.644H5.554A5.56,5.56,0,0,1,0,13.09V5.554A5.56,5.56,0,0,1,5.554,0H13.09a5.56,5.56,0,0,1,5.554,5.554V13.09A5.56,5.56,0,0,1,13.09,18.644ZM5.554,1.875A3.683,3.683,0,0,0,1.875,5.554V13.09a3.683,3.683,0,0,0,3.679,3.678H13.09a3.682,3.682,0,0,0,3.678-3.678V5.554A3.683,3.683,0,0,0,13.09,1.875ZM9.322,14.143a4.822,4.822,0,1,1,4.822-4.822A4.827,4.827,0,0,1,9.322,14.143Zm0-7.768a2.946,2.946,0,1,0,2.946,2.946A2.95,2.95,0,0,0,9.322,6.376Z" fill="#055100"/>
                                                        </svg>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='map-box w-100 order-lg-last order-first'>
                                            <h6>Location MAP</h6>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3851.0766737037657!2d120.7716082635689!3d15.154133325641052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3396e4c4f2704eb9%3A0x2d4a1575ce5a5384!2sArayat%20Municipal%20Hall!5e0!3m2!1sen!2sin!4v1678454717951!5m2!1sen!2sin" title="mapFrame" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                    <div className='footer-info-bottom d-flex flex-md-row flex-column align-items-center justify-content-between w-100 gap-lg-1 gap-4'>
                                        <div className='emergency-contact-box d-flex flex-column w-100 align-items-lg-start align-items-center'>
                                            <h6>Emergency Hotlines</h6>
                                            <ul className='contact-info d-flex flex-column gap-3 mt-2'>
                                                <li className='d-flex flex-column'>
                                                    <span className='sub-title'>police station</span>
                                                    <ul className='d-flex flex-column number-box'>
                                                        <li className='d-flex align-items-center gap2'>
                                                            <div className='icon-bar'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="21.513" height="21.513" viewBox="0 0 21.513 21.513">
                                                                    <path id="ic_perm_phone_msg_24px" d="M23.318,17.94a13.578,13.578,0,0,1-4.267-.681,1.22,1.22,0,0,0-1.219.287L15.2,20.175A18.016,18.016,0,0,1,7.327,12.31L9.956,9.669a1.173,1.173,0,0,0,.3-1.207A13.578,13.578,0,0,1,9.573,4.2,1.2,1.2,0,0,0,8.378,3H4.2A1.2,1.2,0,0,0,3,4.2,20.316,20.316,0,0,0,23.318,24.513a1.2,1.2,0,0,0,1.2-1.2V19.135A1.2,1.2,0,0,0,23.318,17.94Z" transform="translate(-3 -3)" fill="#055100"/>
                                                                </svg>
                                                            </div>
                                                            <Link className="" to="tel:1234567890">Contact Number</Link>
                                                        </li>
                                                        <li className='d-flex align-items-center gap2'>
                                                            <div className='icon-bar'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="21.513" height="21.513" viewBox="0 0 21.513 21.513">
                                                                    <path id="ic_perm_phone_msg_24px" d="M23.318,17.94a13.578,13.578,0,0,1-4.267-.681,1.22,1.22,0,0,0-1.219.287L15.2,20.175A18.016,18.016,0,0,1,7.327,12.31L9.956,9.669a1.173,1.173,0,0,0,.3-1.207A13.578,13.578,0,0,1,9.573,4.2,1.2,1.2,0,0,0,8.378,3H4.2A1.2,1.2,0,0,0,3,4.2,20.316,20.316,0,0,0,23.318,24.513a1.2,1.2,0,0,0,1.2-1.2V19.135A1.2,1.2,0,0,0,23.318,17.94Z" transform="translate(-3 -3)" fill="#055100"/>
                                                                </svg>
                                                            </div>
                                                            <Link className="" to="tel:1234567890">Contact Number</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className='d-flex flex-column'>
                                                    <span className='sub-title'>fire station</span>
                                                    <ul className='d-flex flex-column number-box'>
                                                        <li className='d-flex align-items-center gap2'>
                                                            <div className='icon-bar'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="21.513" height="21.513" viewBox="0 0 21.513 21.513">
                                                                    <path id="ic_perm_phone_msg_24px" d="M23.318,17.94a13.578,13.578,0,0,1-4.267-.681,1.22,1.22,0,0,0-1.219.287L15.2,20.175A18.016,18.016,0,0,1,7.327,12.31L9.956,9.669a1.173,1.173,0,0,0,.3-1.207A13.578,13.578,0,0,1,9.573,4.2,1.2,1.2,0,0,0,8.378,3H4.2A1.2,1.2,0,0,0,3,4.2,20.316,20.316,0,0,0,23.318,24.513a1.2,1.2,0,0,0,1.2-1.2V19.135A1.2,1.2,0,0,0,23.318,17.94Z" transform="translate(-3 -3)" fill="#055100"/>
                                                                </svg>
                                                            </div>
                                                            <Link className="" to="tel:1234567890">Contact Number</Link>
                                                        </li>
                                                        <li className='d-flex align-items-center gap2'>
                                                            <div className='icon-bar'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="21.513" height="21.513" viewBox="0 0 21.513 21.513">
                                                                    <path id="ic_perm_phone_msg_24px" d="M23.318,17.94a13.578,13.578,0,0,1-4.267-.681,1.22,1.22,0,0,0-1.219.287L15.2,20.175A18.016,18.016,0,0,1,7.327,12.31L9.956,9.669a1.173,1.173,0,0,0,.3-1.207A13.578,13.578,0,0,1,9.573,4.2,1.2,1.2,0,0,0,8.378,3H4.2A1.2,1.2,0,0,0,3,4.2,20.316,20.316,0,0,0,23.318,24.513a1.2,1.2,0,0,0,1.2-1.2V19.135A1.2,1.2,0,0,0,23.318,17.94Z" transform="translate(-3 -3)" fill="#055100"/>
                                                                </svg>
                                                            </div>
                                                            <Link className="" to="tel:1234567890">Contact Number</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>   
                                        </div>
                                        <div className='Sitemap-box d-flex flex-column w-100 align-items-lg-start align-items-center'>
                                            <h6>sitemap</h6>
                                            <ul className='sitemap-link-box d-grid gap-1 mt-2 w-100'>
                                                <li><Link exact className="nav-link" to="/">Home</Link></li>
                                                <li><Link exact className="nav-link" to="/business">Business</Link></li>
                                                <li><Link exact className="nav-link" to="/memorandums">Memorandums</Link></li>
                                                <li><Link exact className="nav-link" to="/government">government</Link></li>
                                                <li><Link exact className="nav-link" to="/activities">Activities</Link></li>
                                                <li><Link exact className="nav-link" to="/recreation">recreation</Link></li>
                                                <li><Link exact className="nav-link" to="/blogs">Blogs</Link></li>
                                                <li><Link exact className="nav-link" to="/education">education</Link></li>
                                                {/* <li><Link exact className="nav-link" to="/about-us">About us</Link></li> */}
                                                <li><Link exact className="nav-link" to="/contact-us">contact us</Link></li>
                                            </ul>
                                        </div>    
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-bottom'>
                <div className='container'>
                    <div className='caption-box d-flex flex-lg-row flex-column text-lg-start text-center align-items-lg-center justify-content-between'>
                        <p>©MunicipalityofArayat All Rights Reserved <span className='ms-lg-5 ms-2'>Developed by Outerbox Solutions Inc.</span></p>
                        <ul className='link-caption d-flex align-items-center justify-content-lg-between justify-content-center gap-lg-5 flex-wrap mt-sm-0 mt-2'>
                            <li><Link to="#">Student's Terms And Conditions</Link></li>
                            <li><Link to="#">Terms And Conditions</Link></li>
                            <li><Link to="#">Privacy Policy</Link></li>
                            <li><Link to="#">Cookies Policy</Link></li>
                            <li><Link to="#">Accessibility</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </>
  );
};
