import React from 'react';

export default function Spinner(props) {
  return (
    <>
        <div className="loader-container">
            <div className="spinner"></div>
        </div>
    </>
  )
}
