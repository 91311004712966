import React from 'react';
import useDocumentTitle from "../section_components/useDocumentTitle";
import Article from '../section_components/Article';
import imgSectionOne from '../img/download.jpg';
import imgSectionTwo from '../img/APMP-ecf27d63.jpg';

export default function AboutUs() {
  
  useDocumentTitle('AboutUs - Municipality of Arayat');

  return (
    <>
      <div className='aboutus-page'>
            <Article 
                MainTitle = "Meet the GREAT LEADER"
                Name = "Contact Person Name"
                Email = "emailaddress@gmail.com"
                SubTitle = "Cras condimentum arcu vitae tellus viverra, id maximus mi ornare. Praesent eget lectus fringilla, accumsan diam sed, efficitur sapien."
                
                SectionOneText1 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id ante elit. In placerat sem justo, in consectetur orci luctus vitae. Nullam at enim quis ex posuere euismod. Quisque volutpat laoreet nibh. Suspendisse a suscipit felis. Mauris pretium volutpat vehicula. Quisque euismod libero in dolor ornare, euismod lobortis augue congue. Donec at pharetra tortor. Integer in enim scelerisque nisl porta tristique et in erat."
                SectionOneText2 = "Suspendisse aliquam congue ex, quis blandit enim viverra vitae. Fusce lacinia ac dolor ac eleifend. Maecenas in nunc rhoncus, egestas lectus laoreet, vestibulum enim. Praesent eget turpis cursus, ornare odio non, scelerisque quam. Donec orci tellus, iaculis nec nisl ut, fermentum ultrices justo. Aenean dictum neque vel ex posuere, eget sodales sapien tincidunt. Fusce ultrices tincidunt luctus. Curabitur hendrerit erat orci, imperdiet laoreet nisl luctus ut. Suspendisse ut neque justo. Nunc nulla nulla, dictum ut lorem et, tempus blandit nibh. Suspendisse aliquam congue ex, quis blandit enim viverra vitae. Fusce lacinia ac dolor ac eleifend. Maecenas in nunc rhoncus, egestas lectus laoreet, vestibulum enim. Praesent eget turpis cursus, ornare odio non, scelerisque quam. Donec orci tellus, iaculis nec nisl ut, fermentum ultrices justo. Aenean dictum neque vel ex posuere, eget sodales sapien tincidunt. Fusce ultrices tincidunt luctus. Curabitur hendrerit erat orci, imperdiet laoreet nisl luctus ut. Suspendisse ut neque justo. Nunc nulla nulla, dictum ut lorem et, tempus blandit nibh."
                SectionOneText3 = "Pellentesque vehicula ac nisi a commodo. Nunc sagittis ante quis lacus tempus porta. Etiam vulputate libero quis quam tempus sollicitudin. Sed luctus mi vel massa venenatis, et porta sem lacinia. Etiam lacinia dolor mi, non pharetra augue venenatis vitae. Integer non leo viverra, gravida tortor ut, cursus quam."
                imgSectionOne = {imgSectionOne}

                BoxHeading = "Cras condimentum arcu vitae tellus viverra, id maximus mi ornare. Praesent eget lectus fringilla, accumsan diam sed, efficitur sapien."
                SectionTwoText1 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id ante elit. In placerat sem justo, in consectetur orci luctus vitae. Nullam at enim quis ex posuere euismod. Quisque volutpat laoreet nibh. Suspendisse a suscipit felis. Mauris pretium volutpat vehicula. Quisque euismod libero in dolor ornare, euismod lobortis augue congue. Donec at pharetra tortor. Integer in enim scelerisque nisl porta tristique et in erat."
                SectionTwoText2 = "Suspendisse aliquam congue ex, quis blandit enim viverra vitae. Fusce lacinia ac dolor ac eleifend. Maecenas in nunc rhoncus, egestas lectus laoreet,"
                SectionTwoText3 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id ante elit. In placerat sem justo, in consectetur orci luctus vitae. Nullam at enim quis ex posuere euismod. Quisque volutpat laoreet nibh. Suspendisse a suscipit felis. Mauris pretium volutpat vehicula. Quisque euismod libero in dolor ornare, euismod lobortis augue congue. Donec at pharetra tortor. Integer in enim scelerisque nisl porta tristique et in erat."
                SectionTwoText4 = "Suspendisse aliquam congue ex, quis blandit enim viverra vitae. Fusce lacinia ac dolor ac eleifend. Maecenas in nunc rhoncus, egestas lectus laoreet, vestibulum enim. Praesent eget turpis cursus, ornare odio non, scelerisque quam. Donec orci tellus, iaculis nec nisl ut, fermentum ultrices justo. Aenean dictum neque vel ex posuere, eget sodales sapien tincidunt. Fusce ultrices tincidunt luctus. Curabitur hendrerit erat orci, imperdiet laoreet nisl luctus ut. Suspendisse ut neque justo. Nunc nulla nulla, dictum ut lorem et, tempus blandit nibh."
                SectionTwoText5 = "Pellentesque vehicula ac nisi a commodo. Nunc sagittis ante quis lacus tempus porta. Etiam vulputate libero quis quam tempus sollicitudin. Sed luctus mi vel massa venenatis, et porta sem lacinia. Etiam lacinia dolor mi, non pharetra augue venenatis vitae. Integer non leo viverra, gravida tortor ut, cursus quam."
                imgSectionTwo = {imgSectionTwo}
            />
        </div>    
    </>

  );
}
