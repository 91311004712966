import React from 'react';

export default function Article(props) {
  return (
   <>
        <div className='content-block two-space'>
            <div className='container'>
                <div className='heading-bar d-flex flex-column gap-lg-4 gap-3 text-center'>
                    <div className='heading-text'>
                        <h6 className='text-start'>{props.HeadText}</h6>
                        <h2>{props.MainTitle}</h2>
                        <div className='user-detail d-flex align-items-center justify-content-center mt-sm-4 mt-3'>
                            <span className='name'>{props.Name}</span>
                            <span className='email'>{props.Email}</span>
                        </div>
                    </div>
                    <p className='sub-heading'>{props.SubTitle}</p>
                </div>
            </div>
            <div className='section-block'>
                <div className='section-item section_one'>
                    <div className='container'>
                        <div className='row section-caption g-md-5 g-sm-4 g-3 align-items-center'>
                            <div className='col-lg-6 col-12'>
                                <div className='content-text d-flex flex-column gap-md-4 gap-2'>
                                    <p>{props.SectionOneText1}</p>
                                    <p>{props.SectionOneText2}</p>
                                    <p>{props.SectionOneText3}</p>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12'>
                                <div className='img-bar right-back-overlay-img'>
                                    <img className='img-fluid' src={props.imgSectionOne} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section-item section_two'>
                    <div className='container'>
                        <div className='row g-md-5 g-3 section-caption'>
                            <div className='col-12'>
                                <div className='box-heading text-center'>
                                    <span>{props.BoxHeading}</span>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='content-text d-flex flex-column gap-md-4 gap-2 text-center'>
                                    <p>{props.SectionTwoText1}</p>
                                    <p>{props.SectionTwoText2}</p>
                                    <p>{props.SectionTwoText3}</p>
                                    <p>{props.SectionTwoText4}</p>
                                    <p>{props.SectionTwoText5}</p>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='img-bar left-back-overlay-img'>
                                    <img className='img-fluid' src={props.imgSectionTwo} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
   </>
  )
}
