import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import '../../node_modules/owl.carousel/dist/assets/owl.carousel.css';   
import '../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import LeaderSlider from '../section_components/LeaderSlider';
import ServiceBox from "../section_components/ServiceBox";
import useDocumentTitle from "../section_components/useDocumentTitle";
import { format } from 'date-fns';
import Spinner from "../section_components/Spinner";
import axios from 'axios';
import SocialShare from '../../src/section_components/socialShare';

export default function Home() {

	useDocumentTitle('Home - Municipality of Arayat');
	const [loading, setLoading] = useState(false);
	var image_url = global.config.image_url;
	const regex = /(<([^>]+)>)/ig;

	/* Get Slider Data */

	const [homesliders, setHomeSliders] = useState([])
    var home_slider_url = global.config.api_url + "/get_home_sliders";

    const fetchHomeSliderData = async () => {
		setLoading(true);
        const response = await fetch(home_slider_url)
        const slider_data = await response.json()
        setHomeSliders(slider_data)
		setLoading(false);
    }

    useEffect(() => {
        fetchHomeSliderData()
    }, [])

	/* Get Memorandum Data */

	const [memorandumsData, setMemorandumsData] = useState([])
    var memorandumsUrl = global.config.api_url + "/get_all_memorandums";

    const fetchMemorandumsData = async () => {
        const memorandumsResponse = await fetch(memorandumsUrl);
        const memorandumsLists = await memorandumsResponse.json();
        setMemorandumsData(memorandumsLists);
    }

    useEffect(() => {
        fetchMemorandumsData();
    }, [])

	/* Get Blog Data */

	const [blogs, setBlogs] = useState([])
	var blog_list_url = global.config.api_url + "/get_all_blogs";

    const fetchData = async () => {
		const response = await fetch(blog_list_url)
		const blogs_data = await response.json()
		setBlogs(blogs_data)
    }

    useEffect(() => {
    	fetchData()
    }, [])
	
	/* Get Activities Data */

	const [activities, setActivities] = useState([])
    var activity_list_url = global.config.api_url + "/get_all_activities";

    const fetchDataAc = async () => {
		const response = await fetch(activity_list_url)
		const activitie_data = await response.json()
		setActivities(activitie_data)
    }

    useEffect(() => {
    	fetchDataAc()
    }, [])

	/* banner slider start */
	
	const BannerOption = {
		navText: ['<svg id="arrow-left" xmlns="http://www.w3.org/2000/svg" width="39.383" height="26.581" viewBox="0 0 39.383 26.581"><path id="arrow-left-2" data-name="arrow-left" d="M43.055,17.284v4.7H12.724L20.39,29.6l-3.344,3.323L3.672,19.634,17.046,6.343,20.39,9.666l-7.667,7.619Z" transform="translate(-3.672 -6.343)" fill="#fff"/></svg>','<svg xmlns="http://www.w3.org/2000/svg" width="39.383" height="26.581" viewBox="0 0 39.383 26.581"><g id="arrow-left" transform="translate(39.383 26.581) rotate(180)"><path id="arrow-left-2" data-name="arrow-left" d="M43.055,17.284v4.7H12.724L20.39,29.6l-3.344,3.323L3.672,19.634,17.046,6.343,20.39,9.666l-7.667,7.619Z" transform="translate(-3.672 -6.343)" fill="#fff"/></g></svg>'],
		smartSpeed:1200
	};

	const MemorandumsOption = {
		navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="29.524" height="47.812" viewBox="0 0 29.524 47.812"><path id="ic_chevron_right_24px" d="M5.618,0,0,5.618,18.248,23.906,0,42.194l5.618,5.618L29.524,23.906Z" transform="translate(29.524 47.812) rotate(180)" fill="#fff"/></svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="29.524" height="47.812" viewBox="0 0 29.524 47.812"><path id="ic_chevron_right_24px" d="M5.618,47.812,0,42.194,18.248,23.906,0,5.618,5.618,0,29.524,23.906Z" fill="#fff"/></svg>'],
		smartSpeed:1000,
		responsiveClass:true,
		responsive:{
			0:{
				items:1,
				stagePadding:0
			},
			600:{
				items:2,
				stagePadding:0
			},
			1000:{
				items:3
			}
		}
	};

	/* For current time */
	
	const date = new Date();
    const showTime = date.getHours() + ':' + date.getMinutes();

	/* Get Weather & Location Data */

	const API_endpoint = `https://api.openweathermap.org/data/2.5/weather?`;
	const API_key = `53a8f27f2944059d6d7211216aaa9be7`;

	const [temprecher, setTemprecher] = useState('');
	const [weatherType, setWeatherType] = useState('');
	const [cityName, setCityName] = useState('');

	const fetchCityTemprature = async () => {
		axios.get("https://ipapi.co/json")
		.then((response) => {
			setCityName(response.data.city);
			let finalAPIEndPoint = `${API_endpoint}lat=${response.data.latitude}&lon=${response.data.longitude}&appid=${API_key}&units=metric`;
			axios.get(finalAPIEndPoint)
			.then((responseWeather) => {
				setTemprecher(responseWeather.data.main.temp);
				setWeatherType(responseWeather.data.weather[0].main);
			})
		})
    }

    useEffect(() => {
    	fetchCityTemprature()
    }, [])

  	return (
      	<>
			{loading ? (
				<Spinner />
			) : (
				<div></div>
			)}

			<div className='home_page'>
				<div className='banner_slide_block'>
					{homesliders.length > 0 && (
						<OwlCarousel className="owl-theme banner_slider" loop nav items={1} dots={false} margin={0} {...BannerOption} >
							{homesliders.map(homeslider => (
								<div className='slide_bg' key={`banner_id_${homeslider.id}`} style={{backgroundImage: `url(${image_url+homeslider.image})` }}>
									<div className='container'>
										<div className='slide_box d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center h-100'>
											<div className='slide-box-left d-flex flex-column justify-content-center order-md-first order-last'>
												<span>{homeslider.text}</span>
												<h1>{homeslider.title}</h1>
												<p className='mt-2'>{homeslider.description.replace(regex, '')}</p>
											</div>
											<div className='slide-box-right mt-lg-0 mt-md-4 mb-md-0 mb-3 ms-lg-0 ms-auto order-md-last order-first'>
												<div className='temprecher-box d-flex flex-column gap-2'>
													<div className='location-bar d-flex justify-content-between gap-2'>
														<div className='location-bar-item d-flex flex-column'>
															<p>My Location</p>
															<span className='location-item'>{cityName}</span>
														</div>
														<span className='temprecher-score'>{temprecher}°c</span>
													</div>
													<div className='time-box d-flex justify-content-between'>
														<p className='time-city mt-2'>{showTime} Local time</p>
														<p className='time-type'>{weatherType}</p>
													</div>
												</div>
											</div>		
										</div>
									</div>	
								</div>
							))}
						</OwlCarousel>
					)}
				</div>

				<ServiceBox/>

				<div className='memoradums_slider_block'>
					<div className='container'>
						<div className='section_title'>
							<span>City Ordinances</span>
						</div>

						{/* Home page memorandum slider */}

						<OwlCarousel className="owl-theme memorandums_slider" loop nav  dots={false} margin={20} stagePadding={120} {...MemorandumsOption} > 

							{memorandumsData.length > 0 && (
								memorandumsData.map((memorandum, index) => {
									var imgData = JSON.parse(memorandum.image);
									return (
										<div className='memorandums_box d-flex flex-column' key={`slid_id_${index}`}>
											<span className='title'>{memorandum.title}</span>
											<Link to={`/memorandum/details/${memorandum.id}`}><img src={image_url+imgData[0]} alt="" /></Link>
											
											<div className='caption d-flex flex-column p-3'>
												<span className='heading'>{memorandum.description.replace(regex, '')}</span>
												<div className='bot-bar mt-auto'>
													<div className='bot-bar-caption d-flex justify-content-between align-items-center mt-3'>
														<Link to={`/memorandum/details/${memorandum.id}`} className="btn-bar">Read Memo</Link>
														<SocialShare shareUrl={`/memorandum/details/${memorandum.id}`} />
													</div>
												</div>
											</div>
										</div>
									);
								})
							)}

						</OwlCarousel>

						<div className='btn-block text-end'>
							<Link to="/memorandums" className="btn-bar mt-2 d-inline-block yellow-bg">See more</Link>
						</div>
					</div>
				</div>
				
				<div className='meet_leader_section two-space' id='greatLeader'>
					<div className='container'>
						<div className='row'>
							<div className='col-12'>
								<h3 className='text-center mb-sm-4 mb-3'>MEET THE GREAT LEADER</h3>
							</div>
						</div>
						<div className='Leader_slider_block'>
							<LeaderSlider/>
						</div>
					</div>
				</div>
				<div className='blog-gallery-card green-bg two-space '>
					<div className='container'>
						<div className='row gy-sm-2 gy-2 align-items-center justify-content-between card_block '>
							<div className='col-lg-8 col-12'>
								<div className='row g-3'>
									
									<div className='row g-3 full_photo_card'>
										{/* Home page blog cards */}
										{blogs.length > 0 && (
											blogs.map(blog => (
												<div className='col-md-4 col-12 card-item' key={`blog_id_${blog.id}`}>
													<div className='card'>
														<Link to={`/blog/details/${blog.id}`}><img className='card-img-top' src={image_url+blog.upper_image} alt="" /></Link>
														<div className='card-body px-0 pb-0'>
															<Link to={`/blog/details/${blog.id}`}>
																<h5 className='card-title'>{blog.header_title}</h5>
															</Link>
															<p className='card-text mb-0'>{blog.header_description}</p>
															<div className='bot-bar-caption d-flex align-items-center'>
																<div className='name-time-box w-100'>
																	<div className='bot-bar-caption-item d-flex align-items-center flex-wrap'>
																		<div className='long-box d-flex align-items-center gap-2'>
																			<div className='icon-bar'>
																				<svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
																					width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
																					<path id="Path_4" data-name="Path 4"
																						d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06Zm8.5,15.3a4.176,4.176,0,0,0,2.55-1.53,7.425,7.425,0,0,0,1.19-3.57,2.815,2.815,0,0,0-.85-2.04,2.992,2.992,0,0,0-2.21-1.02h-1.7a4.963,4.963,0,0,1-1.53-.34,1.543,1.543,0,0,1-.51-1.19.883.883,0,0,1,.34-.68,1.289,1.289,0,0,1,.68-.34,1.16,1.16,0,0,1,.85.51c.34.17.51.34.68.34a1.022,1.022,0,0,0,.68-.17,1.022,1.022,0,0,0,.17-.68,2.705,2.705,0,0,0-.85-1.7,7.031,7.031,0,0,0,.85-3.23.366.366,0,0,0-.34-.34,5.255,5.255,0,0,0-1.36-.34A8.5,8.5,0,0,0,5.78,3.4,4.28,4.28,0,0,0,4.25,6.8a4.352,4.352,0,0,0,1.36,3.23,4.644,4.644,0,0,0,3.23,1.36h0v.68a2.182,2.182,0,0,0,.68,1.7,2.475,2.475,0,0,0,1.53,1.02v3.06c0,.17,0,.17.17.34S11.389,18.359,11.559,18.359Z"
																						fill="#055100" />
																				</svg>
																			</div>
																			<p>{blog.contact_person_name}</p>
																		</div>
																		<div className='time-box d-flex align-items-center gap-2'>
																			<div className='icon-bar'>
																				<svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
																					width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
																					<path id="Path_13" data-name="Path 13"
																						d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06ZM14.789,14.789l1.19-1.19-4.25-4.25L10.2,2.55H8.5V10.2a1.543,1.543,0,0,0,.51,1.19.593.593,0,0,0,.34.17Z"
																						fill="#055100" />
																				</svg>
																			</div>
																			<p>{format(new Date(blog.created_at), 'dd-MM-yyyy')}</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											))
										)}
									</div>
								</div>
							</div>
							<div className='col-lg-3 col-12'>
								<div className='row gy-4 scroll_blogcard_list'>
									<div className='col-lg-12 col'>
										{/* Home page blog card side slider */}
										{blogs.length > 0 && (
											blogs.map(blog => (
												<div className='mb-5' key={`blog_slide_id_${blog.id}`}>
													<div className='card'>
														<Link to={`/blog/details/${blog.id}`}><img className='card-img-top' src={image_url+blog.upper_image} alt="" /></Link>
														<div className='card-body px-0 pb-0'>
															<Link to={`/blog/details/${blog.id}`}>
															<h5 className='card-title'>{blog.header_title}</h5>
															</Link>
															<p className='card-text mb-0'>{blog.header_description}</p>
															<div className='bot-bar-caption d-flex align-items-center'>
																<div className='name-time-box w-100'>
																	<div className='bot-bar-caption-item d-flex align-items-center flex-wrap'>
																		<div className='long-box d-flex align-items-center gap-2'>
																			<div className='icon-bar'>
																				<svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
																					width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
																					<path id="Path_4" data-name="Path 4"
																						d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06Zm8.5,15.3a4.176,4.176,0,0,0,2.55-1.53,7.425,7.425,0,0,0,1.19-3.57,2.815,2.815,0,0,0-.85-2.04,2.992,2.992,0,0,0-2.21-1.02h-1.7a4.963,4.963,0,0,1-1.53-.34,1.543,1.543,0,0,1-.51-1.19.883.883,0,0,1,.34-.68,1.289,1.289,0,0,1,.68-.34,1.16,1.16,0,0,1,.85.51c.34.17.51.34.68.34a1.022,1.022,0,0,0,.68-.17,1.022,1.022,0,0,0,.17-.68,2.705,2.705,0,0,0-.85-1.7,7.031,7.031,0,0,0,.85-3.23.366.366,0,0,0-.34-.34,5.255,5.255,0,0,0-1.36-.34A8.5,8.5,0,0,0,5.78,3.4,4.28,4.28,0,0,0,4.25,6.8a4.352,4.352,0,0,0,1.36,3.23,4.644,4.644,0,0,0,3.23,1.36h0v.68a2.182,2.182,0,0,0,.68,1.7,2.475,2.475,0,0,0,1.53,1.02v3.06c0,.17,0,.17.17.34S11.389,18.359,11.559,18.359Z"
																						fill="#055100" />
																				</svg>
																			</div>
																			<p>Long Name Here</p>
																		</div>
																		<div className='time-box d-flex align-items-center gap-2'>
																			<div className='icon-bar'>
																				<svg id="Component_1" data-name="Component 1" xmlns="http://www.w3.org/2000/svg"
																					width="20.398" height="20.398" viewBox="0 0 20.398 20.398">
																					<path id="Path_13" data-name="Path 13"
																						d="M3.06,3.06A9.259,9.259,0,0,1,10.2,0a9.259,9.259,0,0,1,7.139,3.06A9.259,9.259,0,0,1,20.4,10.2a9.259,9.259,0,0,1-3.06,7.139A9.259,9.259,0,0,1,10.2,20.4a9.259,9.259,0,0,1-7.139-3.06A10.179,10.179,0,0,1,0,10.2,9.259,9.259,0,0,1,3.06,3.06ZM14.789,14.789l1.19-1.19-4.25-4.25L10.2,2.55H8.5V10.2a1.543,1.543,0,0,0,.51,1.19.593.593,0,0,0,.34.17Z"
																						fill="#055100" />
																				</svg>
																			</div>
																			<p>1h ago</p>
																		</div>
																	</div>
																</div>
															</div>
															<div className='user-box w-100'>
																<div className='bot-bar-caption-item d-flex align-items-center flex-wrap'>
																	<div className='long-box d-flex align-items-center gap-2'>
																		<div className='icon-bar'>
																			<svg id="Avatar" xmlns="http://www.w3.org/2000/svg" width="27.418" height="27.418"
																				viewBox="0 0 27.418 27.418">
																				<circle id="Ellipse_25" data-name="Ellipse 25" cx="13.709" cy="13.709"
																					r="13.709" transform="translate(0 0)" fill="#055100" />
																				<path id="Union_5" data-name="Union 5"
																					d="M0,11.1V9.714C0,8.188,2.5,6.939,5.551,6.939S11.1,8.188,11.1,9.714V11.1ZM2.775,2.776A2.775,2.775,0,1,1,5.551,5.551,2.776,2.776,0,0,1,2.775,2.776Z"
																					transform="translate(8.402 8.182)" fill="#fff" />
																			</svg>
																		</div>
																		<p>by Name Surname</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											))
										)}
										{/* Home page blog card side slider over */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='gallery-card-block activity-gallery-card two-space bot-space'>
					<div className='container'>
						<ul className='d-grid card_block'>						
							{activities.length > 0 && (
								activities.map(activity => (
									<li key={`activity_id_${activity.id}`}>
										<div className='card'>
											<Link to={`/activity/details/${activity.id}`}><img className='card-img-top' src={image_url+activity.upper_image} alt="" /></Link>
											<div className='card-body'>
												<Link to={`/activity/details/${activity.id}`} ><h5 className='card-title'>{activity.header_title}</h5></Link>
												<p className='card-text mb-0'>{activity.header_description}</p>
												<div className='bot-bar-caption d-flex align-items-center'>
													<div className='like-share-box w-100'>
														<div className='bot-bar-caption-item d-flex align-items-center flex-wrap justify-content-between'>
															<SocialShare shareUrl={`/activity/details/${activity.id}`} />
														</div>
													</div>
												</div>
											</div>
										</div>
									</li>
								))
							)}
						</ul>
						<div className="btn-block text-end">
							<Link to="/activities" className="btn-bar mt-3 d-inline-block yellow-bg">See more</Link>
						</div>
					</div>
				</div>
			</div>
      	</>
  	);
};
