import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from "../section_components/Spinner";
import SocialShare from '../../src/section_components/socialShare';

export default function MemorandumsCard() {

    const [memorandums, setMemorandums] = useState([])
    const [loading, setLoading] = useState(false);

    var activity_list_url = global.config.api_url + "/get_all_memorandums";
    var image_url = global.config.image_url;

    const regex = /(<([^>]+)>)/ig;

    const fetchData = async () => {
        setLoading(true);
        const response = await fetch(activity_list_url)
        const data = await response.json()
        setMemorandums(data)
        setLoading(false);
    }

    useEffect(() => {
        fetchData()
    }, [])

    /* Search Memoradums */

    const [value, setValue] = useState('');

    useEffect(() => {
        const fetchData = async () => {
        try {
            const { data } = await axios.get(
                `${activity_list_url}?setTxt=${value}`
            );
            setMemorandums(data);
        }
        catch (error) {
            console.log(error);
        }
        };

        fetchData();
    }, [value]);


    return (
        <>
            {loading ? (
				<Spinner />
			) : (
				<div></div>
			)}
            <div>
                <div className='memorandums_search'>
                    <div className="constrain">
                        <input className="input-elevated" type="text" placeholder="Search Memorandums" value={value} onChange={(e) => { setValue(e.target.value); }} />
                    </div>
                </div>

                <div className='row gx-md-4 gy-md-5 g-3'>
                    {memorandums.length > 0 && (
                        memorandums.map((memorandum, index) => {
                            var imgData = JSON.parse(memorandum.image);
                            return (
                                <div className='col-lg-4 col-sm-6 col-12' key={`memo_id_${memorandum.id}`}>
                                    <div className='memorandums_box d-flex flex-column'>
                                        <span className='title'>{memorandum.title}</span>
                                        <Link to={`/memorandum/details/${memorandum.id}`}><img src={image_url+imgData[0]} alt="" /></Link>

                                        <div className='caption d-flex flex-column p-3'>
                                            <span className='heading'>{memorandum.description.replace(regex, '')}</span>
                                            <div className='bot-bar mt-auto'>
                                                <div className='bot-bar-caption d-flex justify-content-between align-items-center mt-3'>
                                                    <Link to={`/memorandum/details/${memorandum.id}`} className="btn-bar">Read Memo</Link>
                                                    <SocialShare shareUrl={`/memorandum/details/${memorandum.id}`} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    )}

                    {memorandums.length === 0 &&
                        <h2 className='emptySearch'>Search Result Not Found.</h2>
                    }
                </div>
            </div>
        </>
    )
}
